import React from "react"
import { useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Acordion from "../components/acordion"
import "./platform.scss"

const Platform = () => {
  const accordionData = [
    {
      title: [
        <strong>Week 1</strong>,
        `: How to plan the best strategy and `,
        <strong>execute</strong>,
        ` it.`,
      ],
      paragraph: [
        <strong>Objectives:</strong>,
        <ul>
          <li>Understand the relationship between strategy and tactics.</li>
          <li>
            Adapt the balance of strategic command, leaderships and management
            skills
          </li>
          <li>
            Effectively navigate through a dynamic and complex environment.
          </li>
        </ul>,
        <div>
          <br />
        </div>,
        <strong>Result-oriented experience:</strong>,
        <ul>
          <li>Focus on strategy and planning.</li>
          <li>
            Discover how complex organizations form strategies, in unpredictable
            times.
          </li>
          <li>
            Understand campaign planning & management and command of resources
            to execute strategy.
          </li>
          <li>
            Discover the difference between command, leadership and management.
          </li>
          <li>
            Learn how to balance these skills to execute a viable strategy.
          </li>
        </ul>,
      ],
    },
    {
      title: [
        <strong>Week 2</strong>,
        `: Why `,
        <strong>organizational structure</strong>,
        ` is the secret weapon to winning.`,
      ],
      paragraph: [
        <strong>Objectives:</strong>,
        <ul>
          <li>Understand centralized & de-centralized organizations.</li>
          <li>Identify where this balance lies in your organization.</li>
          <li>
            Learn to apply techniques for competitive advantage, while staying
            adaptable
          </li>
        </ul>,
        <div>
          <br />
        </div>,
        <strong>Result-oriented experience:</strong>,
        <ul>
          <li>Focus on organizational structure and culture.</li>
          <li>
            Learn how culture and leadership work to carry out tactical
            activities to meet strategic goals.
          </li>
          <li>
            Explore how to balance the organization, with a single vision and
            clear objectives.
          </li>
          <li>Discover how to de-centralize the execution of activities.</li>
          <li>
            Learn how to be agile and responsive in a dynamic marketplace.
          </li>
        </ul>,
      ],
    },
    {
      title: [
        <strong>Week 3</strong>,
        `: Build your insight powerhouse with `,
        <strong>data, information and intelligence.</strong>,
      ],
      paragraph: [
        <strong>Objectives:</strong>,
        <ul>
          <li>
            Understand data exploitation tools to become ‘intelligence led’.
          </li>
          <li>
            Map data requirements to strategic questions & operational
            processes.
          </li>
          <li>Identify key areas of improvement in information management.</li>
        </ul>,
        <div>
          <br />
        </div>,
        <strong>Result-oriented experience:</strong>,
        <ul>
          <li>Focus on the pervasiveness of data and deriving intelligence.</li>
          <li>Discover techniques for overcoming data overload.</li>
          <li>
            Learn methods to align data with decision-making requirements &
            strategy.
          </li>
          <li>
            Discover how to be more data centric, but with a defined purpose.
          </li>
          <li>
            Rationalize information flows to make decisions with the best
            available information at hand.
          </li>
        </ul>,
      ],
    },
    {
      title: [
        <strong>Week 4</strong>,
        `: Secure your success with `,
        <strong>environmental analysis and scenario planning.</strong>,
      ],
      paragraph: [
        <strong>Objectives:</strong>,
        <ul>
          <li>Combine strategy, command, insight, resilience & foresight.</li>
          <li>Learn the value and pitfalls of future modelling.</li>
          <li>
            Exploit emerging techniques of scenario development and wargaming.
          </li>
        </ul>,
        <div>
          <br />
        </div>,
        <strong>Result-oriented experience:</strong>,
        <ul>
          <li>Focus on bringing all the tools together.</li>
          <li>
            Explore ‘scenario development’ and ‘environment analysis’, to aid in
            decision-making.
          </li>
          <li>
            Practice wargaming: apply data from environment analysis to a
            strategic plan.
          </li>
          <li>
            Explore ‘consequence management’ to prepare an uncertain future.
          </li>
          <li>
            Learn to build organizational resilience to respond proactively.
          </li>
        </ul>,
      ],
    },
  ]
  const data = useStaticQuery(graphql`
    query {
      pf: file(relativePath: { eq: "P_Banner2@2x.png" }) {
        childImageSharp {
          fluid(maxWidth: 784) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      gt: file(relativePath: { eq: "P_GT_profile@2x.png" }) {
        childImageSharp {
          fluid(maxWidth: 430) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      gc: file(relativePath: { eq: "GARETH_BW@2x.png" }) {
        childImageSharp {
          fluid(maxWidth: 763) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  const [openInterest, SetOpenInterest] = React.useState(false)

  const [formOptions, setFormOptions] = React.useState({
    id: "6a320a66-ff2a-442b-825f-65508d42f070",
    heading: "Registration Request",
  });

  return (
    <Layout
      formTrigger={openInterest}
      cb={SetOpenInterest}
      formOptions={formOptions}
    >
      <SEO title="programs" />
      <section className="gtCourse light">
        <div className="container">
          <div className="col colLeft">
            <h1>Wargaming in Complex Environment</h1>
            <h4>
              4-Week Impactful
              <br />
              Leadership Program
            </h4>
            <h4>with Gareth Tennant</h4>
            <h5>Nov 23 – Dec 18, 2020 &nbsp; & &nbsp; Jan 4 - Jan 29, 2021</h5>
            <button
              onClick={() => {
                setFormOptions({
                  id: "6a320a66-ff2a-442b-825f-65508d42f070",
                  heading: "Registration Request",
                });
                SetOpenInterest(!openInterest);
              }}
              className="pink round"
            >
              Registration Request {">"}
            </button>
          </div>
          <div className="col colRight">
            <Img
              className="spot_shadow gt gareth_img"
              fluid={data.gc.childImageSharp.fluid}
              alt="Gareth Tennant"
              style={{
                bottom: "-30px",
              }}
            />
          </div>
        </div>
      </section>
      <section className="gtPreview blue fold2">
        <div className="container">
          <div className="col colLeft">
            <h1>Executive Coach & Mentor</h1>
            <p className="subtitle">
              <b>
                Don’t allow business leaders to react blindly to dangers. <br />
                Train them to respond proactively, by planning well in advance!
              </b>
            </p>
            <p className="description">
              Empower your business leaders to ask ‘What is most likely to
              happen?’ and not stop there. By further asking ‘What’s the worst
              that can happen?’ ‘What’s the best that can happen?’ ‘What’s least
              likely to happen?’ So, your organization identifies and plans for
              every possible scenario, that awaits in the future.
            </p>
            <p className="description">
              ‘Wargaming in Complex Environment’ will give your business leaders
              the tools and frameworks, adapted from military strategies and
              tactics, to be better prepared to face the challenges of today’s
              new business environment.
            </p>
            <p className="description">- Gareth Tennant</p>
            {/* <span className="serif">
              About our Top Expert <b>Gareth Tennant</b>.
            </span>
            <ul className="bullets">
              <li>
                Former head of operational intelligence for the Royal Marines,
                UK.
              </li>
              <li>
                Specialized in intelligence, surveillance and reconnaissance
                operations{" "}
              </li>
              <li>Global leader in the art of information exploitation </li>
              <li>
                Supports business leaders, governments and security services in
                the art of decision making in complex environments
              </li>
            </ul> */}
          </div>
          {/* <div className="col colRight">
            <Img
              style={{
                width: "auto",
                height: "420px",
              }}
              className="spot_shadow"
              fluid={data.gt.childImageSharp.fluid}
              alt="Gareth Tennant"
            />
          </div> */}
        </div>
        <div className="dottedWhite"></div>
      </section>
      <section className="aboutProgram">
        <div className="container">
          <h1>About the program</h1>
          <p>
            ‘<strong>Wargaming in Complex Environment</strong>’, is a 4-week
            program that will help your business leaders to adapt years of
            highly evolved military intelligence skills to treat the
            organization’s strategic goals as if they were military missions,
            where nothing can be left to chance and all volatility, uncertainty,
            complexity and ambiguity must be identified and planned for.
            <br />
            <br />A brief overview of the program:
          </p>
          <Acordion data={accordionData} />
          <br />
          <button
            onClick={() => {
              setFormOptions({
                id: "1e6b7227-7bbc-4ef1-927f-2c1c485e3ea9",
                heading: "Download Brochure",
              });
              SetOpenInterest(!openInterest);
            }}
            className="pink round"
          >
            Download Brochure >
          </button>
        </div>
      </section>
      <section className="kt">
        <div className="container">
          <h1>Key Takeaways</h1>
          <div className="kt-items">
            <div className="kt-item">
              Get a deeper analysis of your business’ core capabilities.
            </div>
            <div className="kt-item">
              Discover and unlock channels to maximize returns.
            </div>
            <div className="kt-item">
              Implement strategies that are adaptable to uncertainties.
            </div>
            <div className="kt-item">
              Learn how to set aggressive yet attainable KPIs.
            </div>
          </div>
          {/* <p>
            Your business leaders and teams will learn how to ‘Plan for
            failure.’ By asking ‘What is most likely to happen?’ and not stop
            there. By asking ‘What’s the worst that can happen?’ ‘What’s the
            best that can happen?’ ‘What’s most likely to happen?’ ‘What’s least
            likely to happen?’ So, your organization has a roadmap to
            proactively respond, not blindly react to the challenges and
            opportunities that await, in the future.
          </p> */}
        </div>
      </section>
      <section className="pf">
        <div className="">
          {/* <div className="container">
            <div className="col colLeft fluid">
            </div>
          </div> */}
          <div className="container">
            <div className="col colLeft">
              <h1>Program Fee</h1>
              <span className="tall-font">INR 2 lacs</span>
              <span className="italic"> plus 18% GST per company.</span>
              <span className="italic subnote">
                {" "}
                This program is only available to a select group of 5-7
                companies on a first come, first served basis.{" "}
              </span>
              <span>
                <strong>The program fee includes:</strong>
              </span>
              <ul>
                <li>
                  100% virtual access to the ‘Learn. Do. Review. Adapt.’
                  teaching tools.
                </li>
                <li>
                  Complete course materials for ‘Wargaming in Complex World.’
                </li>
                <li>
                  Video sessions with top military expert & mentor Gareth
                  Tennant
                </li>
                <li>Lifetime access to the digital platform ‘Expertpoint’</li>
              </ul>
              {/* <span>Plan for the “next normal” - Book your slot</span> */}
              <button
                onClick={() => {
                  setFormOptions({
                    id: "6a320a66-ff2a-442b-825f-65508d42f070",
                    heading: "Registration Request",
                  });
                  SetOpenInterest(!openInterest);
                }}
                className="pink round"
              >
                Registration Request >
              </button>
            </div>
            <div className="col colRight">
              <Img
                className="soloHero"
                fluid={data.pf.childImageSharp.fluid}
                alt="goldsmith"
              />
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default Platform
